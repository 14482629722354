import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo/SEO';
import styles from './Contact.module.scss';
import ListItem from '../images/boxes/listitem.svg';
import contactBoxes from '../images/boxes/kontakt_boxer.svg';
import Img from 'gatsby-image';
import Layout from '../components/layout/Layout';

export const query = graphql`
  query ContactPageQuery {
    bergen: file(relativePath: { eq: "kontakt/bergen.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ohc: file(relativePath: { eq: "kontakt/OHCpartner.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zestas: file(relativePath: { eq: "kontakt/zestaspartner.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kc: file(relativePath: { eq: "kontakt/KCpartner.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vg: file(relativePath: { eq: "kontakt/VGpartner.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    azane: file(relativePath: { eq: "kontakt/Azanepartner.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    h2i: file(relativePath: { eq: "kontakt/H2ipartner.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gexcon: file(relativePath: { eq: "kontakt/GXpartner.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ContactPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <Layout>
        <SEO title="Contact us. Do you have questions on matters related to gas safety? We can probably help. Don’t hesitate to make contact." />
        <div className="grid_text_image grid_vertical_padding sectionShort pb-32">
          <div>
            <h1 className={styles.welcomeText}>How can we help you?</h1>
            <p className={styles.descriptionText}>
              Can we help you with anything related to the safety of hydrogen, ammonia or other
              gases?{' '}
            </p>

            <div className={styles.descriptionText}>
              Our most common project categories include:
              <div className={styles.projectListContainer}>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> CFD consequence
                  assessment for dispersion or explosion.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Risk assessment of
                  gas handling facilities, gas fuelled ships or other gas applications.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Design safety
                  review/screening or HAZID-workshops for sites or vessels.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Help to understand
                  an accident or an incident that has occurred.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Training course or
                  a presentation.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Cooperation on
                  projects.
                </div>
              </div>
            </div>
            <p className={styles.descriptionText}>
              If so, we are happy to discuss the matter further. Please contact us per email or
              phone. We are physically located in Bergen, Norway.
            </p>
            <div className={styles.contactContainer}>
              <div className={styles.contactRow}>
                <div className={styles.contactElement}>Email:</div>
                <div className={styles.contactElement}>mail@hyexsafe.com</div>
              </div>
              <div className={styles.contactRow}>
                <div className={styles.contactElement}>Phone:</div>
                <div className={styles.contactElement}>(+47) 91 17 17 87</div>
              </div>
            </div>
          </div>
          <div>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.bergen.childImageSharp.fluid} alt="Picture of Bergen" />
            </figure>
          </div>
          <div className={styles.partnerTitleBox}>
            <h1 className={styles.welcomeText}>Partners and alliances</h1>
          </div>
        </div>

        <div className={`grid_vertical_padding ${styles.partnerContainerTop}`}>
          <div className={styles.partnerColumn}>
            <figure className={styles.partnerImage}>
              <Img fluid={data.gexcon.childImageSharp.fluid} alt="GEXCON logo" />
            </figure>
            <p>
              Our CEO, Olav Roald Hansen, has previously worked at Gexcon for nearly 20 years. At
              Gexcon he contributed to the development and validation of the FLACS CFD model, with
              significant effort towards hydrogen modelling. His familiarity of FLACS’ abilities is
              used by HYEX Safety to perform simulations beyond the defined capabilities of the
              model, for example to model detonations, tank bursts, and liquid hydrogen releases.
              HYEX Safety has an interest keeping FLACS as the leading CFD consequence model for
              hydrogen safety studies and has therefore entered into a partnership with the
              development team at Gexcon with the aim of supporting the development of new models
              and functionality to make the software better suited for hydrogen safety modeling to
              the benefit of all users.
            </p>
            <a href="https://www.gexcon.com"> Gexcon.com</a>
            <p>
              <br />
            </p>
          </div>
          <div className={styles.partnerColumn}>
            <figure className={styles.partnerImage}>
              <Img fluid={data.azane.childImageSharp.fluid} alt="Azane Fuel Solutions logo" />
            </figure>
            <p>
              Azane Fuel Solutions, an Amon Maritime/ECONNECT Energy joint venture, develops a
              turn-key bunkering solution for ammonia fuel. AFS is the owner of the “Ammonia fuel
              bunkering network” project, funded by the Norwegian Research Council, working to build
              the first green ammonia bunkering terminal along the Norwegian coast. HYEX Safety is a
              project partner, contributing with expertise on ammonia safety and performing risk
              assessments of the bunkering solutions. Other partners include Yara, Sintef, OHC, and
              terminal operators Fjordbase and GOT.
            </p>
            <a href="https://www.azanefs.com"> Azanefs.com</a>
            <p>
              <br />
            </p>
          </div>
          <div className={styles.partnerColumn}>
            <figure className={styles.partnerImage}>
              <Img fluid={data.ohc.childImageSharp.fluid} alt="OHC logo" />
            </figure>
            <p>
              Ocean Hyway Cluster is in Norway the leading network for hydrogen-based solutions in
              the maritime sector. The cluster is active in facilitating collaboration between its
              members and different sectors, and it is a centre for competence for the hydrogen
              value chain. HYEX Safety is an active member of the cluster, participating in several
              of the cluster projects and working closely with many of the other members.
            </p>
            <a href="https://www.oceanhywaycluster.no"> OceanHywayCluster.no</a>
            <p>
              <br />
            </p>
          </div>
        </div>

        <div className={`grid_vertical_padding ${styles.partnerContainer}`}>
          <div className={styles.partnerColumn}>
            <figure className={styles.partnerImage}>
              <Img fluid={data.zestas.childImageSharp.fluid} alt="ZESTAS logo" />
            </figure>
            <p>
              The Zero Emissions Ship Technology Association is an international cluster with the
              aim of promoting a rapid transition to zero-emisstion maritime solutions. HYEX Safety
              is a member of ZESTAs with expertise within safety related to hydrogen-based fuels for
              the maritime. We help enabling this transition by finding and documenting safe design
              solutions for vessels developed to use hydrogen-based fuels. We contribute to ZESTAs
              with our experience, participating in meetings and presenting our work at conferences.
            </p>
            <a href="https://zestas.org"> ZESTAs.org</a>
            <p>
              <br />
            </p>
          </div>
          <div className={styles.partnerColumn}>
            <figure className={styles.partnerImage}>
              <Img fluid={data.h2i.childImageSharp.fluid} alt="HYDROGENi logo" />
            </figure>
            <p>
              HYDROGENi is a Norwegian R&D initiative aiming to develop relevant competence and
              technical solutions for the implementation of hydrogen as a fuel in society. The
              centre, with more than 50 industry and academic partners, will receive a grant from
              the Norwegian Research Council of 200 MNOK over 8 years and will educate 35
              PhD/postdocs and more than 100 master/bachelor candidates. HYEX Safety looks forward
              to contributing with our experience in hydrogen and ammonia safety.
            </p>
            <a href="https://www.sintef.no/siste-nytt/2022/nytt_hydrogen_forskningssenter/">
              {' '}
              HYDROGENi article
            </a>
            <p>
              <br />
            </p>
          </div>
        </div>

        <div className={styles.boxesPattern}>
          <img src={contactBoxes} alt="boxes" />
        </div>
      </Layout>
    </>
  );
};

export default ContactPage;
